<template>
    <form class="single-aroma">
        <div class="calendar-controller">
            <div class="controller-wrapper">
                <button
                    type="button"
                    class="btn-controller"
                    @click="updateDate('subtract', 'y')"
                >
                    <i class="icon icon-arrow_left"></i>
                </button>
                <span class="txt">{{ year }}</span>
                <button
                    type="button"
                    class="btn-controller"
                    @click="updateDate('add', 'y')"
                >
                    <i class="icon icon-arrow_right"></i>
                </button>
            </div>

            <div class="controller-wrapper">
                <button
                    type="button"
                    class="btn-controller"
                    @click="updateDate('subtract', 'M')"
                >
                    <i class="icon icon-arrow_left"></i>
                </button>
                <span class="txt">{{ month }}月</span>
                <button
                    type="button"
                    class="btn-controller"
                    @click="updateDate('add', 'M')"
                >
                    <i class="icon icon-arrow_right"></i>
                </button>
            </div>
        </div>
        <div class="content-panel">
            <BaseSelect
                title="芳療師"
                name="member_id"
                :options="aromaList"
                rootClass="aroma-select"
                isHasOptionAll
                isFilterSearch
            />

            <FullCalendar ref="fullCalendar" :options="calendarOptions" />

            <div class="add-room" @click="openAddReservation">
                <div class="img-wrap">
                    <img src="@/assets/tgd/reserve/add-btn.svg" alt="" />
                </div>
            </div>
        </div>
    </form>
    <FixedOrderInfo/>
</template>

<script>
import { ref, reactive, computed, watch, nextTick, onMounted } from "vue";
import { useStore } from "vuex";
import moment from "moment";
import { useForm } from "vee-validate";
import { debounce } from "lodash";

import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";

import useHandleLightboxOpen from "@/hooks/common/useHandleLightboxOpen";
import useUpdateSelectedEventId from "@/hooks/reservation/useUpdateSelectedEventId";
import FixedOrderInfo from '@/components/inline/FixedOrderInfo';

export default {
    name: "SingleAroma",
    components: {
        FullCalendar,
        FixedOrderInfo
    },
    setup() {
        const { state, getters, commit, dispatch } = useStore();
        const { openAddReservation } = useHandleLightboxOpen();
        const { updateSelectedEventId } = useUpdateSelectedEventId();
        const storeId = computed(() => state.auth.store_id);

        // // 取得芳療師名單
        dispatch("reservation/getAromatherapistList", {
            store_id: storeId.value,
        });

        const aromatherapistList = computed(()=>{
            return state.reservation.aromatherapistList.map(item => {
                return {
                    id: item.id,
                    name: `${item.member_code}${item.nickname}`
                }
            }) || []
        })
        const aromaList = computed(()=>{
            if(!aromatherapistList.value.length) return [];
            setFieldValue("selected_aroma", 0);
            return [
                {
                    id: 0,
                    name: "全部",
                },
                ...aromatherapistList.value
            ]
        })

        const dateInfo = {
            year: computed(() => getters["reservationTable/getYear"]),
            month: computed(() => getters["reservationTable/getMonth"]),
            selectedDate: computed(() => state.reservationTable.selectedDate),
        };

        const { setFieldValue, values } = useForm();

        // Fullcalendar
        const fullCalendar = ref();
        const fullCalendarAPI = computed(() => {
            if (!fullCalendar.value) return;
            return fullCalendar.value.getApi();
        });
        const events = computed(
            () => getters["reservationTable/getMonthlyEventList"]
        );
        const calendarOptions = {
            plugins: [dayGridPlugin, interactionPlugin],
            initialView: "dayGridMonth",
            headerToolbar: {
                left: "",
                center: "",
                right: "",
            },
            aspectRatio: 2.08,
            events: (info, successCallback, failureCallback) => {
                successCallback(events.value);
            },
            eventOrder: ["time", "title"],
            eventDidMount(event) {
                const isReservation =
                    event.event.classNames.includes("reservation");

                if (isReservation) {
                    const insertedEl = event.el.querySelector(
                        ".fc-event-title-container"
                    );
                    const { time, customerName, reservedNum } =
                        event.event.extendedProps;
                        insertedEl.innerHTML = `
                            <span class="time">${time}</span>
                            <span class="customer-name">${customerName}</span>
                            <span class="reserved-num">${reservedNum}人</span>
                        `;
                }
            },
            eventClick(info) {
                const id = info.event.id;
                const classNames = info.event.classNames;
                const isReservation = classNames.includes("reservation");

                if (!isReservation) return;

                updateSelectedEventId(id);
            },
        };
        // 切換日期
        const updateDate = (type, unit) => {
            const momentController = moment(dateInfo.selectedDate.value);

            if (type === "add") {
                momentController.add(1, unit);

                if (unit === "y") {
                    fullCalendarAPI.value.nextYear();
                } else {
                    fullCalendarAPI.value.next();
                }
            } else {
                momentController.subtract(1, unit);

                if (unit === "y") {
                    fullCalendarAPI.value.prevYear();
                } else {
                    fullCalendarAPI.value.prev();
                }
            }

            commit("reservationTable/update", {
                key: "selectedDate",
                value: momentController.format("YYYY-MM-DD"),
            });
        };
        // 更新事件資料
        watch(events, (arr) => {
            fullCalendarAPI.value.refetchEvents();
        });

        // 需要設定日期，並去拿芳療師的資料
        commit("reservationTable/fallback2Today");
        dispatch("reservationTable/getAromaStatus", {
            store_id: state.auth.store_id,
        });
        
        const getEventList = debounce(() => {
            dispatch("reservationTable/getList", {
                store_id: state.auth.store_id,
                member_id: values.member_id,
                reqType: "monthly",
            });
        }, 300);
        watch(
            [dateInfo.selectedDate, () => values.member_id],
            (arr, oldArr) => {
                if (!arr[0] || !String(arr[1])) return;

                commit("reservationTable/update", {
                    key: "monthlySelectedMemberId",
                    value: arr[1],
                });

                getEventList();
            }
        );

        // 芳療師初始化
        watch(aromaList, (newArr, oldArr) => {
            if (!newArr.length) {
                return;
            }

            if (newArr.length && oldArr.length) {
                let isEqual = true;

                for (let i = 0; i < newArr.length; i++) {
                    if (newArr[i].id !== oldArr[i].id) {
                        isEqual = false;
                        break;
                    }
                }
                if (isEqual && values.member_id) return;
            }

            nextTick(() => {
                setFieldValue("member_id", newArr[0].id);
                commit("reservationTable/update", {
                    key: "monthlySelectedMemberId",
                    value: newArr[0].id,
                });
            });
        });

        // 初始化
        onMounted(() => {
            commit("reservationTable/update", {
                key: "viewStatus",
                value: "monthly",
            });

            commit("reservationTable/update", {
                key: "selectedDate",
                value: moment().format("YYYY-MM-DD"),
            });

            dispatch("reservationTable/initSingleRequest", {
                store_id: state.auth.store_id,
                date: moment().format("YYYY-MM-DD"),
            });
        });

        return {
            aromaList,
            calendarOptions,
            openAddReservation,
            ...dateInfo,
            fullCalendar,
            updateDate,
            events
        };
    },
};
</script>

<style lang="scss">
@import "~@/css/mixins";
@import "~@/css/grid";

.single-aroma {
    position: relative;

    .calendar-controller {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: -55px;
        margin-bottom: 26px;
        font-size: 16px;
        font-weight: 600;

        .controller-wrapper {
            display: flex;
            align-items: center;
            padding: 0 10px;
        }

        .btn-controller {
            border: none;
            background-color: transparent;
        }

        .txt {
            padding: 0 20px;
        }
    }

    .content-panel {
        padding: 46px 75px;
        position: relative;
        background: linear-gradient(-13deg, #fff, rgba(229, 229, 229, 1), #fff);
        @media (max-width: 1200px) {
            padding: 20px 15px;
        }
    }

    .input-group {
        max-width: 270px;
        border: 1px solid #ffffff;
        border-radius: 11px;
        height: 53px;
        box-shadow: -3px -4px 8px 0 rgba(255, 255, 255, 0.61);
        background: transparent
            linear-gradient(180deg, #ececec 0%, #f9f9f9 35%, #ffffff 100%) 0% 0%
            no-repeat padding-box;
        z-index: 2;
        .input-group-text {
            background: transparent
                linear-gradient(180deg, #ececec 0%, #f9f9f9 35%, #ffffff 100%)
                0% 0% no-repeat padding-box;
            border: none;
            border-radius: 11px 0px 0px 11px;
            padding: 15px;
            position: relative;
            font-weight: bold;
            @include phone {
                padding: 12px;
            }
            &:after {
                content: "";
                position: absolute;
                border-right: 1px solid #eee;
                top: 10px;
                bottom: 10px;
                right: 0;
                z-index: 1;
            }
            .select-coupon {
                border-radius: 11px 0px 0px 11px;
                padding-right: 32px;
                height: 100%;
                color: #0f2a68;
                background: #f9f9f9
                    url("~@/assets/tgd/lightbox/box1-arrow1.svg") no-repeat 90%;
            }

            .select-money {
                border-radius: 11px 0px 0px 11px;
                padding-right: 32px;
                height: 100%;
                color: #a4641a;
                background: #f9f9f9
                    url("~@/assets/tgd/lightbox/box1-arrow2.svg") no-repeat 90%;
            }
        }
        .end-text {
            color: rgba(0, 0, 0, 0.5);
        }
        .form-control {
            background: transparent
                linear-gradient(180deg, #ececec 0%, #f9f9f9 35%, #ffffff 100%)
                0% 0% no-repeat padding-box;
            border: none;
            border-radius: 0px 11px 11px 0px;
            padding: 14px;
            font: normal normal 600 16px/22px Open Sans;
        }
        select {
            flex: 1;
            background: #f9f9f9
                url("~@/assets/tgd/lightbox/select-arrow-box.svg") no-repeat
                101%;
            border: none;
            border-radius: 11px;
            padding: 15px;
            font: normal normal 600 16px/22px Open Sans;
            appearance: none;
            filter: contrast(100%);
            transition: all 0.3s;
            &:hover {
                filter: contrast(130%);
            }
        }
        .custom-rounded {
            border-radius: 0px 11px 11px 0px;
        }
        .btn {
            width: 100%;
            border-radius: 11px;
            padding: 15px;
            background: transparent
                linear-gradient(
                    282deg,
                    #ebc496 0%,
                    #a4641a 36%,
                    #b57831 88%,
                    #a4641a 100%
                );
            box-shadow: 0px 3px 6px #00000029;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border: 1px solid #ffffff85;
        }
    }

    .add-room {
        position: absolute;
        right: 40px;
        bottom: 40px;
        z-index: 1;
    }

    // fullcalendar
    .fc-media-screen {
        background-color: #fff;
    }

    .fc {
        .fc-toolbar.fc-header-toolbar {
            margin: 0;
        }

        .fc-daygrid-day.fc-day-today {
            background-color: rgba(225, 225, 225, 1);
        }
    }

    .fc-scrollgrid {
        border: none;
    }

    .fc-col-header-cell {
        padding: 10px;
    }

    .fc-scrollgrid-section {
        > th,
        > td {
            border: none;
        }
    }

    .fc-col-header,
    .fc-scrollgrid-sync-table {
        th,
        td {
            border-color: rgba(245, 245, 245, 1);

            &:first-child {
                border-left: none;
            }

            &:last-child {
                border-right: none;
            }
        }
    }

    .fc-col-header-cell-cushion {
        font-size: 16px;
        color: rgba(52, 52, 52, 1);
    }

    .fc-daygrid-day-number {
        font-size: 14px;
        color: rgba(52, 52, 52, 1);
    }

    .fc-daygrid-event-harness {
        display: inline-block;
        font-size: 14px;

        .holiday {
            padding: 3px 8px;
            border-radius: 5px;
        }

        .reservation {
            padding: 8px 10px;
            border-radius: 5px;
            box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

            .fc-event-title-container {
                display: flex;
                align-items: center;
            }

            .time {
                margin-left: 9px;
                font-weight: 600;
                position: relative;

                &::before {
                    content: "";
                    @include size(6px);
                    border-radius: 50%;
                    position: absolute;
                    right: calc(100% + 3px);
                    top: 50%;
                    transform: translateY(-50%);
                    background-color: #fff;
                }
            }

            .customer-name {
                padding: 0 8px;
                margin: 0 8px;
                border-left: 1px solid #fff;
                border-right: 1px solid #fff;
            }
        }
    }
}

.aroma-select {
    max-width: 270px;
}

.fc-daygrid-event {
    cursor: pointer;
}
</style>
