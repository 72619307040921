import store from "@/store";
import useHandleLightboxOpen from "@/hooks/common/useHandleLightboxOpen";

const { commit } = store;
const { openAddReservation } = useHandleLightboxOpen();

export default function () {
    const updateSelectedEventId = (id, status, type) => {
        commit("reservationTable/update", [
            {
                key: "selectedEventId",
                value: id,
            },
            {
                key: "selectedReserveStatus",
                value: status,
            },
            {
                key: "selectedReserveType",
                value: type,
            },
        ]);
        openAddReservation();
    };

    return {
        updateSelectedEventId,
    };
}
